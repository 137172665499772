import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import { hydrateRoot } from 'react-dom/client'
import { useEffect, startTransition, StrictMode } from 'react'
import * as Sentry from '@sentry/remix'
import version from '../.version.json'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://2c013a3e7b3a47e8a13c895be19622d8@o1395555.ingest.sentry.io/6718498',
    tracesSampleRate: 0,
    release: version,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.remixRouterInstrumentation(
          useEffect,
          useLocation,
          useMatches
        ),
      }),
    ],
    enabled: process.env.NODE_ENV === 'production',
  })
}

const hydrate = () => {
  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <RemixBrowser />
      </StrictMode>
    )
  })
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate)
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1)
}

// hydrateRoot(document, <RemixBrowser />)
